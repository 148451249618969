import React, { useEffect, useState } from 'react';
import '../style/CategoriesStyles.css';
import '../style/Fonts.css';
import { NavLink } from 'react-router-dom';

function CategoriesWidget() {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		fetch('https://moskva-kniga.ru/api/categories')
			.then(response => response.json())
			.then(data => {
				setCategories(data);
			})
			.catch(error => {
				console.error('Error fetching data:', error); // Log errors if they occur
			});
	}, []);

	return (
		<div className="categoriesSection">
			<h2 className='categoriesText'>Категории</h2>
			<div className="categories-row">
				{categories.map(category => (
					<NavLink key={category.ID} to={`/books?category_id=${category.ID}`} className="category-link">
						<div
							className="category-image-button"
							style={{ backgroundImage: `url(https://moskva-kniga.ru/api/image/${category.imageURL})` }}
						>
							<span className="category-name">{category.name}</span>
						</div>
					</NavLink>
				))}
			</div>
		</div>
	);
}

export default CategoriesWidget;
