import React from 'react';
import HeaderAndCategories from './HeaderAndCategories';
import BooksForSaleWidget from './BooksForSaleWidget';
import QuoteWidget from './QuoteWidget';
import Footer from './Footer';
import '../style/HomeStyles.css';

function Home() {

    return (
        <div>
            <div className="section-container">
                <div className="categories-container">
                    <HeaderAndCategories /> { }
                </div>

                <BooksForSaleWidget />
            </div>
            {/* <PublishmentWidget /> */}
            <QuoteWidget />
            {/* <SubscribeWidget /> */}
            <Footer />
        </div>
    );
}

export default Home;
