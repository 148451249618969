import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../style/ContactsScreen.css';

function Contacts() {
    return (
        <div className="contacts-page">
            <div className="header-bg-default">
				<Header />
			</div>

            <div className="contacts-content">
                <p className="centered-text">Общество с ограниченной ответственностью<br></br>«Издательский дом «Столичная Энциклопедия»».</p>

                <div className="row">
                    <p className="first-text">Адрес: </p>
                    <p className="second-text">105005, г. Москва, ул. Радио, д. 23/9, а/я 12.</p>
                </div>
                <div className="row">
                    <p className="first-text">Телефон: </p>
                    <p className="second-text">495 777 95 16</p>
                </div>
                <div className="row">
                    <p className="first-text">Электронный адрес: </p>
                    <p className="second-text">pervov-izdat@yandex.ru</p>
                </div>
                <div className="row">
                    <p className="first-text">Время работы: </p>
                    <p className="second-text">с 10:00 до 16:00, кроме выходных и праздничных дней. В пятницу с 10:00 до 15:00.</p>
                </div>

                <p className="final-text">Перед поездкой с помощью навигатора следует набрать в графе поиска навигатора: ЦНИИчермет им Бардина</p>
            </div>

            <Footer />
        </div>
    );
}

export default Contacts;
