import { useNavigate, NavLink } from 'react-router-dom';
import React, { useRef, useEffect, useState } from 'react';
import '../style/BooksForSaleStyles.css';

function BooksForSaleWidget() {
  const [books, setBooks] = useState([]);
  const scrollContainer = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch books from your backend
    fetch("https://moskva-kniga.ru/api/books-for-sale")
      .then(response => response.json())
      .then(data => {
        console.log('Fetched books:', data); // Log the fetched books data
        // Sort the books by publishmentYear in descending order
        const sortedBooks = data.sort((a, b) => b.publishmentYear - a.publishmentYear);
        setBooks(sortedBooks);
      })
      .catch(error => {
        console.error('Error fetching books:', error); // Log any errors
      });
  }, []);

  const handleScroll = (direction) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({
        left: direction === 'right' ? 300 : -300,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="books-for-sale-widget">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 className='allBooksText'>Книги в продаже</h2>
        <button
          className="allBooksButton"
          onClick={() => navigate("/books")}
        >
          все книги ➙
        </button>
      </div>

      <div style={{ position: 'relative' }}>
        <div className="books-for-sale-list" ref={scrollContainer}>
          {books.map((book) => (
            <NavLink key={book.ID} to={`/book/${book.ID}`} className="book-image-button">
              <img src={`https://moskva-kniga.ru/api/image/${book.imageURL}`} alt="Book Cover" />
            </NavLink>
          ))}
        </div>

        <button
          className="scroll-button scroll-button-left"
          left="true"
          onClick={() => handleScroll('left')}
        >
        </button>
        <button
          className="scroll-button scroll-button-right"
          right="true"
          onClick={() => handleScroll('right')}
        >
        </button>
      </div>
    </div>
  );
}

export default BooksForSaleWidget;
