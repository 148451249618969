import React from 'react';
import '../style/Footer.css';

function Footer() {
  return (
    <footer className="footer-container">
      <p className="footer-text">© 2023 «Столичная энциклопедия»</p>
      <p className="footer-text">pervov-izdat@yandex.ru</p>
    </footer>
  );
}

export default Footer;
