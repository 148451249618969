import React, { useState, useEffect } from 'react';
import Header from './Header';
import { useParams } from 'react-router-dom';
import '../style/SingleBookScreen.css';

function SingleBookScreen() {
	const { book_id } = useParams();
	const [book, setBook] = useState(null);
	const [category, setCategory] = useState(null);

	useEffect(() => {
		fetch(`https://moskva-kniga.ru/api/book/${book_id}`)
			.then(res => res.json())
			.then(data => {
				console.log('Fetched book:', data); // Log the fetched book data
				setBook(data);

				// Fetch category information if categoryID is available
				if (data.categoryID) {
					fetch(`https://moskva-kniga.ru/api/category/${data.categoryID}`)
						.then(res => res.json())
						.then(categoryData => {
							console.log('Fetched category:', categoryData); // Log the fetched category data
							setCategory(categoryData);
						})
						.catch(error => console.error('Failed to fetch category:', error));
				}
			})
			.catch(error => console.error('Failed to fetch book:', error));
	}, [book_id]);

	if (!book) {
		return <p>Loading book details...</p>;
	}

	return (
		<div>
			<div className="header-bg-default">
				<Header />
			</div>
			<div style={{ paddingLeft: '5%', paddingTop: '50px' }}>
				<h2>{book.name}</h2>
				<div style={{ display: 'flex' }}>
					<div className='book-image'>
						<img src={`https://moskva-kniga.ru/api/image/${book.imageURL}`} alt={book.name} />
					</div>
					<div className="book-details" style={{ marginLeft: '10%' }}>
						<div className="book-detail-row">
							<div className="book-detail-label">Статус:</div>
							<div className="book-detail-content">{book.isBeingSold ? 'В продаже' : 'Нет в наличии'}</div>
						</div>
						<div className="book-detail-row">
							<div className="book-detail-label">Год публикации:</div>
							<div className="book-detail-content">{book.publishmentYear}</div>
						</div>
						<div className="book-detail-row">
							<div className="book-detail-label">Жанр:</div>
							<div className="book-detail-content">{category ? category.name : 'N/A'}</div>
						</div>
						<div className="book-detail-row">
							<div className="book-detail-label">Количество страниц:</div>
							<div className="book-detail-content">{book.numPages}</div>
						</div>
						<div className="book-detail-row">
							<div className="book-detail-label">УДК:</div>
							<div className="book-detail-content">{book.udkCode}</div>
						</div>
						<div className="book-detail-row">
							<div className="book-detail-label">ББК:</div>
							<div className="book-detail-content">{book.bbkCode}</div>
						</div>
						<div className="book-detail-row">
							<div className="book-detail-label">N32:</div>
							<div className="book-detail-content">{book.n32Code}</div>
						</div>
					</div>
				</div>

				<h3 className='description'>Описание</h3>
				<p className='book-description'>{book.description}</p>
			</div>
		</div>
	);
}

export default SingleBookScreen;
