import React, { useEffect, useState, useRef, useCallback } from 'react';
import Header from './Header';
import { NavLink, useLocation } from 'react-router-dom';
import '../style/BooksScreen.css';
import Footer from './Footer';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function BooksScreen() {
	const query = useQuery();
	const categoryId = query.get("category_id");
	const [allBooks, setAllBooks] = useState([]);
	const [displayedBooks, setDisplayedBooks] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(categoryId ? parseInt(categoryId, 10) : 0);
	const [filter, setFilter] = useState('Being_Sold');
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [selectedCategoryName, setSelectedCategoryName] = useState("Жанры");

	const buttonRefs = useRef({});

	// Fetch categories
	useEffect(() => {
		const fetchCategories = async () => {
			const response = await fetch('https://moskva-kniga.ru/api/categories', { credentials: 'include' });
			const data = await response.json();
			console.log('Fetched categories:', data); // Log fetched categories
			setCategories(data);

			// Set initial selected category name if categoryId is present
			if (categoryId) {
				const selectedCatObj = data.find(cat => cat.ID === parseInt(categoryId, 10));
				if (selectedCatObj) {
					setSelectedCategoryName(selectedCatObj.name);
				}
			}
		};

		fetchCategories();
	}, [categoryId]);

	// Fetch books
	useEffect(() => {
		const fetchBooks = async () => {
			let initialCriteria = 'https://moskva-kniga.ru/api/books?is_being_sold=True';

			if (categoryId) {
				initialCriteria += `&category_id=${categoryId}`;
			}

			const allBooksCriteria = 'https://moskva-kniga.ru/api/books';

			const [initialBooksResponse, allBooksResponse] = await Promise.all([
				fetch(initialCriteria, { credentials: 'include' }),
				fetch(allBooksCriteria, { credentials: 'include' })
			]);

			let initialBooks = await initialBooksResponse.json();
			let allBooks = await allBooksResponse.json();

			console.log('Fetched initial books:', initialBooks); // Log initial books
			console.log('Fetched all books:', allBooks); // Log all books

			const sortByPublishmentYearDesc = (a, b) => b.publishmentYear - a.publishmentYear;
			initialBooks = initialBooks.sort(sortByPublishmentYearDesc);
			allBooks = allBooks.sort(sortByPublishmentYearDesc);

			setAllBooks(allBooks);

			if (initialBooks.length === 0 && filter === 'Being_Sold') {
				setFilter('Michail_Pervov');
			} else if (initialBooks.length === 0 && filter === 'Michail_Pervov') {
				setFilter('Publishment');
			} else {
				setDisplayedBooks(initialBooks);
			}
		};

		fetchBooks();
	}, [categoryId, filter]);

	// Map selected category name to ID
	useEffect(() => {
		if (categories.length > 0 && typeof selectedCategory === 'string') {
			const selectedCatObj = categories.find(cat => cat.name === selectedCategory);
			if (selectedCatObj) {
				setSelectedCategory(selectedCatObj.ID);
			} else {
				setSelectedCategory(0);
			}
		}
	}, [categories, selectedCategory]);

	// Filter books
	const filterBooks = useCallback(() => {
		console.log('Applying filter:', filter); // Log current filter
		console.log('Selected category ID:', selectedCategory); // Log selected category
		console.log('All books:', allBooks); // Log all books before filtering

		const selectedCatId = parseInt(selectedCategory, 10);
		let filteredBooks = [];

		if (selectedCatId === 0) {
			switch (filter) {
				case 'Being_Sold':
					filteredBooks = allBooks.filter(b => b.isBeingSold === true);
					break;
				case 'Michail_Pervov':
					filteredBooks = allBooks.filter(b => b.isPervovs === true);
					break;
				case 'Publishment':
					filteredBooks = allBooks.filter(b => b.isPervovs === false);
					break;
				default:
					break;
			}
		} else {
			switch (filter) {
				case 'Being_Sold':
					filteredBooks = allBooks.filter(b => b.isBeingSold === true && b.categoryID === selectedCatId);
					break;
				case 'Michail_Pervov':
					filteredBooks = allBooks.filter(b => b.isPervovs === true && b.categoryID === selectedCatId);
					break;
				case 'Publishment':
					filteredBooks = allBooks.filter(b => b.isPervovs === false && b.categoryID === selectedCatId);
					break;
				default:
					break;
			}
		}

		console.log('Filtered books:', filteredBooks); // Log filtered books
		setDisplayedBooks(filteredBooks);
	}, [filter, allBooks, selectedCategory]);

	const buttonMapping = {
		'Being_Sold': 'Сегодня в продаже',
		'Michail_Pervov': 'Книги Михаила Первова',
		'Publishment': 'Книги издательства'
	};

	useEffect(() => {
		filterBooks();
	}, [filterBooks]);

	return (
		<div>
			<div className="header-bg-default">
				<Header />
			</div>
			<div className="filter-container">
				<div className="filter-buttons">
					{Object.keys(buttonMapping).map(buttonKey => (
						<button
							key={buttonKey}
							ref={ref => buttonRefs.current[buttonKey] = ref}
							onClick={() => {
								setFilter(buttonKey);
							}}
							className={`custom-button ${filter === buttonKey ? 'active' : ''}`}
						>
							{buttonMapping[buttonKey]} {/* Use the button mapping here */}
							{filter === buttonKey && <div className="highlight-line"></div>}
						</button>
					))}
				</div>

				{/* Category Dropdown */}
				<div className="dropdown-container">
					<select
						className="category-dropdown"
						value={typeof selectedCategory === 'number' ? selectedCategoryName : 'Жанры'}
						onChange={e => {
							const selectedName = e.target.value;
							setSelectedCategoryName(selectedName);
							const selectedCatObj = categories.find(cat => cat.name === selectedName);
							setSelectedCategory(selectedCatObj ? selectedCatObj.ID : 0);
						}}
						onClick={() => setDropdownOpen(prev => !prev)}
					>
						<option value="Жанры">Жанры</option>
						{categories.map(cat => (
							<option key={cat.ID} value={cat.name}>{cat.name}</option>
						))}
					</select>
					<img
						src={isDropdownOpen ? '/arrow_up.svg' : '/arrow_down.svg'}
						alt="Dropdown icon"
						className="dropdown-icon"
					/>
				</div>
			</div>

			<hr className="gray-line" />

			{/* Books List */}
			<div className="books-list">
				{displayedBooks.map(book => (
					<NavLink key={book.ID} to={`/book/${book.ID}`}>
						<div className="book">
							<div className="book-year">{book.publishmentYear}</div>
							<img src={`https://moskva-kniga.ru/api/image/${book.imageURL}`} alt={book.name} />
							<div className="book-name">{book.name}</div>
						</div>
					</NavLink>
				))}
			</div>
			<Footer />
		</div>
	);
}

export default BooksScreen;
