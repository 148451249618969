import React from 'react';
import Header from './Header';
import CategoriesWidget from './CategoriesWidget';

function HeaderAndCategories() {
    return (
        <div className="header-categories-bg">
            <Header />
            <CategoriesWidget/> {/* Pass down props */}
        </div>
    );
}

export default HeaderAndCategories;
