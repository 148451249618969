import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './components/HomeScreen';
import BooksScreen from './components/BooksScreen';
import ContactsScreen from './components/ContactsScreen';
import SingleBookScreen from './components/SingleBookScreen';

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/book/:book_id" element={<SingleBookScreen />} />
				<Route path="/books" element={<BooksScreen />} />
				<Route path="/contacts" element={<ContactsScreen />} />
				<Route path="/" element={<HomeScreen />} exact />
				{/* Add more routes as needed */}
			</Routes>
		</Router>
	);
}

export default App;