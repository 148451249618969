import React, { useState } from 'react';
import '../style/HeaderStyles.css';
import '../style/Fonts.css';
import { useNavigate, NavLink } from 'react-router-dom';


function Header() {
	const [searchTerm, setSearchTerm] = useState("");
	const books = [
		{ id: 1, name: "Ребятам о ракетах" },
		{ id: 2, name: "Москва оружейная" },
		{ id: 3, name: "Рассказы о русских ракетах. Книга третья" },
		{ id: 4, name: "Рассказы о русских ракетах. Книга вторая" },
		{ id: 5, name: "Рассказы о русских ракетах. Книга первая" },
		{ id: 6, name: "«Аннушки» - часовые Москвы" },
		{ id: 7, name: "Родилась в Москве «Катюша»" },
		{ id: 8, name: "Зенитное ракетное оружие противовоздушной обороны страны" },
		{ id: 9, name: "Ракетные комплексы РВСН" },
		{ id: 10, name: "Ракетное оружие ракетных войск стратегического назначения" },
		{ id: 11, name: "Межконтинентальный баллистические ракеты СССР и России" },
		{ id: 12, name: "НИИ-5 – МНИИПА – НТЦ ВКО АО «Концерн ВКО «Алмаз – Антей». Создание автоматизированной ПВО Советского Союза и Российской Федерации. Посвящается 90-летию НИИ-5" },
		{ id: 13, name: "Очерки истории отечественного танкостроения (бронетанковой техники). 1920 - 2020 гг" },
		{ id: 14, name: "Настольная книга директора, или Ваш авторитет" },
		{ id: 15, name: "От «Спутника» до «Глонасса». История развития отечественных автоматических космических аппаратов. Посвящается 65-летию запуска первого в мире искусственного спутника Земли." },
		{ id: 16, name: "История отечественных автоматизированных систем управления войсками и оружием" },
		{ id: 17, name: "История вооружения РВСН" },
		{ id: 18, name: "Время Гагарина. Посвящается 60-летию первого полета человека в космос" },
		{ id: 19, name: "А.И.Хюпенен. судьба моя, жизнь моя – армия. Записки командующего зенитными ракетными войсками Войск ПВО страны" },
		{ id: 20, name: "Москва для фронта, москва для победы! 1941-1945 гг." },
		{ id: 21, name: "Системы вооружения ракетно-космической обороны России. Том 2. Системы ПРН, ККП, ПКО" },
		{ id: 22, name: "Системы вооружения ракетно-космической обороны России. Том 1. Системы стратегической противоракетной обороны." },
		{ id: 23, name: "Очерки истории вооружения российского флота" },
		{ id: 24, name: "Атомная эра российской авиации" },
		{ id: 25, name: "Судостроительная промышленность России. Государственные деятели. Руководители предприятий. Ученые. Конструкторы. Биографическая энциклопедия" },
		{ id: 26, name: "История развития систем управления, радиотехнических систем и наземного автоматизированного комплекса управления отечественной ракетно-космической техники." },
		{ id: 27, name: "История развития отечественных ракетно-космических двигательных установок" },
		{ id: 28, name: "История развития отечественной наземной ракетно-космической инфраструктуры" },
		{ id: 29, name: "История развития отечественных автоматических космических аппаратов" },
		{ id: 30, name: "История развития отечественной пилотируемой космонавтики" },
		{ id: 31, name: "История развития отечественного ракетостроения" },
		{ id: 32, name: "История отечественной радиоэлектронной техники для Сухопутных войск" },
		{ id: 33, name: "История отечественной электронной вычислительной техники. Изд. 2-е испр. и доп" },
		{ id: 34, name: "Очерки истории артиллерии государства Российского" },
		{ id: 35, name: "Создатели российских лазеров" },
		{ id: 36, name: "История отечественной радиолокационной авиационной техники" },
		{ id: 37, name: "Москва для фронта, москва для победы! 1941-1945 гг." },
		{ id: 38, name: "История отечественной радиолокации. Изд. 2-е, испр. и доп" },
		{ id: 39, name: "Оборонно-промышленный комплекс России. Промышленность обычных вооружений. Биографическая энциклопедия" },
		{ id: 40, name: "Оборонно-промышленный комплекс России. Авиационная и атомная промышленность. Биографическая энциклопедия" },
		{ id: 41, name: "Отечественная электронная вычислительная техника. Биографическая энциклопедия" },
		{ id: 42, name: "История отечественной электронной вычислительной техники" },
		{ id: 43, name: "История отечественных средств связи" },
		{ id: 44, name: "Военные лазеры России" },
		{ id: 45, name: "Атомное оружие России. Биографическая энциклопедия" },
		{ id: 46, name: "История отечественной радиолокации" },
		{ id: 47, name: "Диалектика технологий воздушно-космической обороны" },
		{ id: 48, name: "Отечественная радиолокация. Биографическая энциклопедия" },
		{ id: 49, name: "Космонавтика и ракетостроение России. Биографическая энциклопедия" },
		{ id: 50, name: "Машиностроители России. Биографическая энциклопедия" },
		{ id: 51, name: "Техника и вооружение Сухопутных войск России. Биографическая энциклопедия" },
		{ id: 52, name: "Электроника России. Биографическая энциклопедия" },
		{ id: 53, name: "Авиация России. Биографическая энциклопедия" },
		{ id: 54, name: "Радиолокация России. Биографическая энциклопедия" },
		{ id: 55, name: "Космонавтика и ракетостроение. Биографическая энциклопедия" },
		{ id: 56, name: "Календарь «Радиоэлектроника России»." },
		{ id: 57, name: "Системы ракетно-космической обороны России создавались так." },
		{ id: 58, name: "История отечественной морской радиоэлектронной техники" },
		{ id: 59, name: "Судостроение России 2008 г. Биографическая энциклопедия." },
		{ id: 60, name: "Конструкторы космической техники, или Есть такое ремесло." },
		{ id: 61, name: "История отечественной электроники, том 1." },
		{ id: 62, name: "История отечественной электроники, том 2." },
		{ id: 63, name: "Связь России. Биографическая энциклопедия." },
		{ id: 64, name: "Ракеты штурмуют космос, или Удивительные путешествия Ракетонавта во времени и пространстве Книга рассказов для детей и взрослых, которые когда-то были детьми." },
		{ id: 65, name: "История отечественной радиолокационной авиационной техники." },
	];

	const filteredBooks = books.filter(book => book.name.toLowerCase().includes(searchTerm.toLowerCase()));
	const navigate = useNavigate();

	function handleBookClick(bookId) {
		navigate(`/book/${bookId}`);
	}

	function handleSearchChange(e) {
		setSearchTerm(e.target.value);
	}



	return (
		<div className="header-container">
			<div className="logo-text">Издательский дом</div>
			<div className="wall-image-container">
				<img src={`${process.env.PUBLIC_URL}/left_tower.svg`} alt="Left Tower" className="tower-image" />

				<div
					className="stretch-wall-background"
					style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/wall.svg)` }}>
					<div className="sub-text1">СТОЛИЧНАЯ</div>
					<div className="sub-text2">ЭНЦИКЛОПЕДИЯ</div>
				</div>

				<img src={`${process.env.PUBLIC_URL}/right_tower.svg`} alt="Right Tower" className="tower-image" />
			</div>

			<div className="nav-list" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 5%' }}>
				<div style={{ display: 'flex', gap: '10px', paddingRight: '10px', paddingTop: '10px' }}>
					<NavLink to="/" className="nav-text" activeClassName="active-link">Главная</NavLink>
					<NavLink to="/books" className="nav-text" activeClassName="active-link">Книги</NavLink>
					<NavLink to="/contacts" className="nav-text" activeClassName="active-link">Контакты</NavLink>
				</div>

				<input type="text" placeholder="Поиск книги" className="search-input" onChange={handleSearchChange} />
				{searchTerm && filteredBooks.length > 0 && (
					<div className="search-book-list">
						{filteredBooks.map(book => (
							<div key={book.id} className="book-name" onClick={() => handleBookClick(book.id)}>
								{book.name}
							</div>
						))}
					</div>
				)}


			</div>
		</div>
	);
}

export default Header;
