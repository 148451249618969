import React from 'react';
import '../style/QuoteWidget.css';

const quotes = [
  {
    text: "Есть после шести вечера вредно для фигуры, \nа читать книги нашего издательства полезно в любое время суток.",
    author: "Ваша «Столичная энциклопедия»"
  },
  {
    text: "Никогда не читайте меня зажмурившись.\nПомните: это неприлично.\nПриступая к чтению моих книг,\nобязательно широко откройте глаза.",
    author: "Михаил Первов"
  },
  {
    text: "Умные люди\nвсегда держат ноги в тепле,\nа книги нашего издательства –\nна книжной полке",
    author: "Ваша «Столичная энциклопедия»"
  }
  /* {
      text: "Second quote text here...",
      author: (
          <>
              <span style={{fontWeight: 'bold'}}>Second</span> quote author here...
          </>
      )
  },
  {
      text: "Third quote text here...",
      author: "Third quote author here..."
  } */
];

function renderTextWithLineBreaks(text) {
  return text.split('\n').map((str, index, array) => (
      <React.Fragment key={index}>
          {str}
          {index === array.length - 1 ? null : <br />}
      </React.Fragment>
  ));
}

function QuoteWidget() {
  const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

  return (
    <>
      <div class="row-container-top">
          <div class="icon-container">
              <img src="/quote_top.svg" alt="Quote Top" />
          </div>
      </div>

      <p className='quoteText'>{renderTextWithLineBreaks(randomQuote.text)}</p>
      <p className='quoteAuthor'>
        {randomQuote.author}
      </p>

      <div className="icon-line-wrapper">
          <img src="/quote_bottom.svg" alt="Center Icon" className="center-icon" />
      </div>

    </>
  );
}

export default QuoteWidget;
